import { useEffect, useRef } from "react";
import { Stage, Layer, Image } from "react-konva";
import "./App.css";
import useImage from "use-image";
import { useState } from "react";

import Items from "./Items";

const ImageComponent = ({ path }) => {
    const [image] = useImage(process.env.PUBLIC_URL + path, "anonymous");

    return <Image image={image} width={720} height={689} />;
};

const App = () => {
    const [database, setDatabase] = useState(null);
    const [imageTraits, setImageTraits] = useState({});

    const stageRef = useRef(null);

    const handleExport = () => {
        const link = document.createElement("a");
        link.download = `MATRIX_${Date.now()}_IMAGE.png`;
        link.href = stageRef.current.toDataURL();
        link.click();
    };

    const handleRandomize = () => {
        const newTraits = {};
        Object.keys(imageTraits).forEach((element) => {
            const randomImage = Math.floor(
                Math.random() * database.traits[element].length
            );

            const image = database.traits[element][randomImage];
            newTraits[element] = image;
        });

        setImageTraits(newTraits);
    };

    const setTrait = (trait, image, name) => {
        setImageTraits({
            ...imageTraits,
            [trait]:
                name === "None"
                    ? null
                    : {
                          image: image,
                          name: name,
                      },
        });
    };

    const setDefaults = () => {
        let DEFAULT_TRAITS = {};
        Object.keys(database.traits).forEach((trait) => {
            if (
                !database.traits[trait] ||
                !database.traits[trait].length ||
                database.traits[trait][0].name !== "Main"
            ) {
                DEFAULT_TRAITS[trait] = null;
                return;
            }

            DEFAULT_TRAITS[trait] = database.traits[trait][0];
        });

        setImageTraits(DEFAULT_TRAITS);
    };

    useEffect(() => {
        fetch("/database.json")
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setDatabase(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        if (!database) return;

        setDefaults();
    }, [database]);

    if (!database) return null;

    return (
        <div className="App">
            <div className="container mx-auto">
                <h1>Matrix meme maker!</h1>
                <div className="flex main-site">
                    <div className="right-side flex-1 p-4 overflow-auto">
                        {Object.keys(database.traits)
                            .filter(
                                (trait) =>
                                    !database.hidden_traits.includes(trait)
                            )
                            .map((trait, index) => (
                                <Items
                                    key={index}
                                    items={[
                                        {
                                            name: "None",
                                            image: "/assets/none.svg",
                                        },
                                        ...database.traits[trait],
                                    ]}
                                    trait={trait}
                                    setImageTraits={setTrait}
                                    activeItem={
                                        imageTraits[trait]
                                            ? imageTraits[trait].image
                                            : null
                                    }
                                />
                            ))}
                    </div>
                    <div className="left-side w-[400px] bg-gray-200 p-4">
                        <Stage width={720} height={689} ref={stageRef}>
                            <Layer>
                                {Object.keys(imageTraits).map((trait) => {
                                    if (!imageTraits[trait]) return null;
                                    return (
                                        <ImageComponent
                                            key={trait}
                                            path={`/assets/${trait}/${imageTraits[trait].image}`}
                                        />
                                    );
                                })}
                            </Layer>
                        </Stage>
                        <div className="buttons">
                            <button type="button" onClick={setDefaults}>
                                <svg
                                    width="800px"
                                    height="800px"
                                    viewBox="0 0 21 21"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke="#000000"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        transform="matrix(0 1 1 0 2.5 2.5)"
                                    >
                                        <path d="m3.98652376 1.07807068c-2.38377179 1.38514556-3.98652376 3.96636605-3.98652376 6.92192932 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8" />

                                        <path
                                            d="m4 1v4h-4"
                                            transform="matrix(1 0 0 -1 0 6)"
                                        />
                                    </g>
                                </svg>
                                Reset
                            </button>
                            <button type="button" onClick={handleRandomize}>
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-5 h-5 text-primary"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M504.971 359.029c9.373 9.373 9.373 24.569 0 33.941l-80 79.984c-15.01 15.01-40.971 4.49-40.971-16.971V416h-58.785a12.004 12.004 0 0 1-8.773-3.812l-70.556-75.596 53.333-57.143L352 336h32v-39.981c0-21.438 25.943-31.998 40.971-16.971l80 79.981zM12 176h84l52.781 56.551 53.333-57.143-70.556-75.596A11.999 11.999 0 0 0 122.785 96H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12zm372 0v39.984c0 21.46 25.961 31.98 40.971 16.971l80-79.984c9.373-9.373 9.373-24.569 0-33.941l-80-79.981C409.943 24.021 384 34.582 384 56.019V96h-58.785a12.004 12.004 0 0 0-8.773 3.812L96 336H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h110.785c3.326 0 6.503-1.381 8.773-3.812L352 176h32z"></path>
                                </svg>
                                Random
                            </button>
                            <button
                                type="button"
                                className="download"
                                onClick={handleExport}
                            >
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 512 512"
                                    className="w-5 h-5 text-[#FDF6E7]"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"></path>
                                </svg>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
