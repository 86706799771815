import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function LeftArrow() {
    const visibility = React.useContext(VisibilityContext);
    const isFirstItemVisible = visibility.useIsVisible("first", true);

    return (
        <Arrow
            disabled={isFirstItemVisible}
            onClick={() => visibility.scrollPrev()}
        >
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 320 512"
                className="w-5 h-5 text-[#764824]"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
            </svg>
        </Arrow>
    );
}

export function RightArrow() {
    const visibility = React.useContext(VisibilityContext);
    const isLastItemVisible = visibility.useIsVisible("last", false);

    return (
        <Arrow
            disabled={isLastItemVisible}
            onClick={() => visibility.scrollNext()}
        >
            <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 320 512"
                className="w-5 h-5 text-[#764824]"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
            </svg>
        </Arrow>
    );
}

function Arrow({ children, disabled, onClick }) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={{
                cursor: disabled ? "not-allowed" : "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                opacity: disabled ? "0.2" : "1",
                userSelect: "none",
            }}
        >
            {children}
        </button>
    );
}
