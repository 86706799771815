import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";

import { LeftArrow, RightArrow } from "./Arrows";
import { Card } from "./Card";
import usePreventBodyScroll from "./usePreventBodyScroll";

import { DragDealer } from "./DragDealer";

const onWheel = (apiObj, ev) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
};

const Items = ({ trait, items, setImageTraits, activeItem }) => {
    const { disableScroll, enableScroll } = usePreventBodyScroll();

    const dragState = React.useRef(new DragDealer());

    const handleDrag =
        ({ scrollContainer }) =>
        (ev) =>
            dragState.current.dragMove(ev, (posDiff: number) => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });

    return (
        <>
            <p className="items-title">{trait}</p>
            <div className="items-list">
                <div
                    onMouseEnter={disableScroll}
                    onMouseLeave={(e) => {
                        enableScroll();
                        dragState.current.dragStop(e);
                    }}
                >
                    <ScrollMenu
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onWheel={onWheel}
                        onMouseDown={(e) => dragState.current.dragStart(e)}
                        onMouseUp={(e) => dragState.current.dragStop(e)}
                        onMouseMove={handleDrag}
                    >
                        {items.map(({ name, image }) => (
                            <Card
                                title={name}
                                image={image}
                                trait={trait}
                                key={image}
                                setImageTraits={setImageTraits}
                                activeItem={activeItem}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
        </>
    );
};
export default Items;
