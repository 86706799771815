import React from "react";

export function Card({ title, image, trait, setImageTraits, activeItem }) {
    return (
        <div
            role="button"
            tabIndex={0}
            className={`card ${activeItem === image || !activeItem && title === 'None' ? 'active' : ''} ${title === 'None' ? 'none' : ''}`}
            onClick={() => setImageTraits(trait, image, title)}
        >
            <div>{title}</div>
            <img src={title === "None" ? image : `/assets/${trait}/${image}`} />
        </div>
    );
}
